<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>Gabentest 1.0</h1>
        <h2>Diese Erfahrungen habe ich in meinem Leben gemacht:</h2>
        <p>Dann man los, alles durchklicken ....</p>
      </div>
    </div>
    <form>
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="question col-sm-8"><h3> Frage </h3></div>
            <div class="answer col-sm-4">
              <div class="row">
                <div class="col-sm-2 p-3 mb-5 rounded head-option gray-4">
                  Fast immer
                </div>
                <div class="col-sm-2 p-3 mb-5 rounded head-option gray-3">
                  Oft
                </div>
                <div class="col-sm-2 p-3 mb-5 rounded head-option gray-2">
                  Manch- mal
                </div>
                <div class="col-sm-2 p-3 mb-5 rounded head-option gray-1">
                  Selten
                </div>
                <div class="col-sm-2 p-3 mb-5 rounded head-option gray-0">
                 Nie
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-top cust-row" v-for="item in questions" :key="item.number">
        <div class="col-sm-12">
          <div class="row">
            <div class="question col-sm-8">{{ item.question }}</div>
            <div class="answer col-sm-4">
              <div class="row">
                <div class="col-sm-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Fast immer">
                  <input :id="'anwop4'+item.number" class="answer-option btn-check radio-bt" type="radio" @change="calcGiftAnswers($event)" :key="item.number" :name="item.number" value="4"/>
                  <label class="btn btn-secondary gray-4 radio-bt" :for="'anwop4'+item.number">4</label>
                </div>
                <div class="col-sm-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Oft">
                  <input :id="'anwop3'+item.number" class="answer-option btn-check radio-bt" type="radio" @change="calcGiftAnswers($event)" :key="item.number" :name="item.number" value="3"/>
                  <label class="btn btn-secondary gray-3 radio-bt" :for="'anwop3'+item.number">3</label>
                </div>
                <div class="col-sm-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Manchmal">
                  <input :id="'anwop2'+item.number" class="answer-option btn-check radio-bt" type="radio" @change="calcGiftAnswers($event)" :key="item.number" :name="item.number" value="2"/>
                  <label class="btn btn-secondary gray-2 radio-bt" :for="'anwop2'+item.number">2</label>
                </div>
                <div class="col-sm-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Selten">
                  <input :id="'anwop1'+item.number" class="answer-option btn-check radio-bt" type="radio" @change="calcGiftAnswers($event)" :key="item.number" :name="item.number" value="1"/>
                  <label class="btn btn-secondary gray-1 radio-bt" :for="'anwop1'+item.number">1</label>
                </div>
                <div class="col-sm-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Nie">
                  <input :id="'anwop0'+item.number" class="answer-option btn-check radio-bt" type="radio" @change="calcGiftAnswers($event)" :key="item.number" :name="item.number" value="0"/>
                  <label class="btn btn-secondary gray-0 radio-bt" :for="'anwop0'+item.number">0</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr><hr>
    <div class="row">
      <div class="col-sm-12">
        <h3>Fazit:</h3>
        <div class="row border-top cust-row" v-for="(value, index) in Object.entries(giftAnswersResultsSum)" :key="index">
          <div class="question col-sm-8">{{ value[0] }}</div>
          <div class="answer col-sm-4">{{ value[1] }}</div>
        </div>
        <p><a href="https://campus-d.de/wp-content/uploads/2021/04/Gabenfragebogen.pdf" target="_blank">Zum PDF</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup () {
    const giftAnswersResults = ref(
      {
        Apostel: { 1: 0, 21: 0, 41: 0, 61: 0, 81: 0 },
        Barmherzigkeit: { 2: 0, 22: 0, 42: 0, 62: 0, 82: 0 },
        Dienen: { 3: 0, 23: 0, 43: 0, 63: 0, 83: 0 },
        Ehelosigkeit: { 4: 0, 24: 0, 44: 0, 64: 0, 84: 0 },
        Erkenntnis: { 5: 0, 25: 0, 45: 0, 65: 0, 85: 0 },
        Evangelist: { 6: 0, 26: 0, 46: 0, 66: 0, 86: 0 },
        Gastfreundschaft: { 7: 0, 27: 0, 47: 0, 67: 0, 87: 0 },
        Geben: { 8: 0, 28: 0, 48: 0, 68: 0, 88: 0 },
        Gebet: { 9: 0, 29: 0, 49: 0, 69: 0, 89: 0 },
        Glaube: { 10: 0, 30: 0, 50: 0, 70: 0, 90: 0 },
        Hilfeleitung: { 11: 0, 31: 0, 51: 0, 71: 0, 91: 0 },
        Hirtendienst: { 12: 0, 32: 0, 52: 0, 72: 0, 92: 0 },
        Lehre: { 13: 0, 33: 0, 53: 0, 73: 0, 93: 0 },
        Leitung: { 14: 0, 34: 0, 54: 0, 74: 0, 94: 0 },
        Missionar: { 15: 0, 35: 0, 55: 0, 75: 0, 95: 0 },
        Prophetie: { 16: 0, 36: 0, 56: 0, 76: 0, 96: 0 },
        Seelsorge: { 17: 0, 37: 0, 57: 0, 77: 0, 97: 0 },
        Unterscheidung_der_Geister: { 18: 0, 38: 0, 58: 0, 78: 0, 98: 0 },
        Verwaltung: { 19: 0, 39: 0, 59: 0, 79: 0, 99: 0 },
        Weisheit: { 20: 0, 40: 0, 60: 0, 80: 0, 100: 0 }
      }
    )
    const giftAnswersResultsSum = ref(
      {
        Apostel: 0,
        Barmherzigkeit: 0,
        Dienen: 0,
        Ehelosigkeit: 0,
        Erkenntnis: 0,
        Evangelist: 0,
        Gastfreundschaft: 0,
        Geben: 0,
        Gebet: 0,
        Glaube: 0,
        Hilfeleitung: 0,
        Hirtendienst: 0,
        Lehre: 0,
        Leitung: 0,
        Missionar: 0,
        Prophetie: 0,
        Seelsorge: 0,
        Unterscheidung_der_Geister: 0,
        Verwaltung: 0,
        Weisheit: 0
      }
    )
    const giftAnswersValuation = {
      Apostel: [1, 21, 41, 61, 81],
      Barmherzigkeit: [2, 22, 42, 62, 82],
      Dienen: [3, 23, 43, 63, 83],
      Ehelosigkeit: [4, 24, 44, 64, 84],
      Erkenntnis: [5, 25, 45, 65, 85],
      Evangelist: [6, 26, 46, 66, 86],
      Gastfreundschaft: [7, 27, 47, 67, 87],
      Geben: [8, 28, 48, 68, 88],
      Gebet: [9, 29, 49, 69, 89],
      Glaube: [10, 30, 50, 70, 90],
      Hilfeleitung: [11, 31, 51, 71, 91],
      Hirtendienst: [12, 32, 52, 72, 92],
      Lehre: [13, 33, 53, 73, 93],
      Leitung: [14, 34, 54, 74, 94],
      Missionar: [15, 35, 55, 75, 95],
      Prophetie: [16, 36, 56, 76, 96],
      Seelsorge: [17, 37, 57, 77, 97],
      Unterscheidung_der_Geister: [18, 38, 58, 78, 98],
      Verwaltung: [19, 39, 59, 79, 99],
      Weisheit: [20, 40, 60, 80, 100]
    }
    const questions = ref(
      [
        {
          number: 4,
          question: 'Ich bin unverheiratet und habe keine starke Sehnsucht nach einem Ehepartner. (Verheiratete bitte „Nie“ ankreuzen).'
        },
        {
          number: 5,
          question: 'Ich nehme jede Gelegenheit wahr, in der Bibel und in christlicher Literatur zu forschen, um neue Erkenntnisse und Einsichten zu gewinnen.'
        },
        {
          number: 6,
          question: 'Ich erlebe es, dass durch mein Reden und Handeln andere Menschen zum Glauben an Jesus Christus kommen.'
        },
        {
          number: 7,
          question: 'Es ist mir ein Anliegen, Menschen, die in Not sind, aufzunehmen und gegebenenfalls zu bewirten.'
        },
        {
          number: 8,
          question: 'Es gelingt mir, meine Finanzen so zu verwalten, dass ich über den „Zehnten“ hinaus Geld für die Arbeit am Reich Gottes geben kann.'
        },
        {
          number: 9,
          question: 'Ich bringe Nöte oder Anliegen über Wochen und Monate hinweg intensiv im Gebet vor Gott.'
        },
        {
          number: 10,
          question: 'Wenn ich eine Zusage oder Verheißung Gottes habe, fällt es mir nicht schwer, fest darauf zu vertrauen – auch wenn die Umstände dagegen sprechen.'
        },
        {
          number: 11,
          question: 'Ich nehme anderen Christen Arbeit ab, um sie für ihren Dienst zu entlasten.'
        },
        {
          number: 12,
          question: 'Ich bin gerne auch über längere Zeit für das geistliche Wohl und Wachstum anderer Christen verantwortlich.'
        },
        {
          number: 13,
          question: 'Ich kann anderen geistliche Wahrheiten verständlich und logisch erklären.'
        },
        {
          number: 14,
          question: 'Ich arbeite gerne auf konkrete Ziele zu und kann andere auch für deren Erreichung gewinnen und motivieren.'
        },
        {
          number: 15,
          question: 'Es fällt mir leicht, mich in einer fremden Kultur zurechtzufinden.'
        },
        {
          number: 16,
          question: 'Ich erhalte Eindrücke von Gott, die für eine bestimmte Situation oder Person zutreffend sind.'
        },
        {
          number: 17,
          question: 'Ich erlebe, wie mir Gott im Gespräch mit anderen Einsicht in die jeweilige Problemsituation schenkt.'
        },
        {
          number: 18,
          question: 'Ich kann in geistlichen Angelegenheiten Wahrheit von Irrtum unterscheiden.'
        },
        {
          number: 19,
          question: 'Mir macht es Freude, detaillierte Pläne zu entwerfen, die es der Gemeinde ermöglichen, Schritt für Schritt bestimmte Ziele zu erreichen.'
        },
        {
          number: 20,
          question: 'Ich erkenne meist eher als andere Christen Lösungen für Probleme und Schwierigkeiten.'
        },
        {
          number: 21,
          question: 'Mir macht es Freude, eine geistliche Arbeit (Hauskreis, Gemeinde, usw.) an einem Ort anzufangen, wo bisher nichts oder sehr wenig gewesen ist.'
        },
        {
          number: 22,
          question: 'Ich erfahre, wie ich mein Mitgefühl gegenüber Menschen in sozialen, physischen und psychischen Nöten natürlich zum Ausdruck bringen kann.'
        },
        {
          number: 23,
          question: 'Ich erledige gerne anfallende Arbeiten in der Gemeinde, die andere vielleicht als unwichtig und unattraktiv einstufen.'
        },
        {
          number: 24,
          question: 'Ich bin unverheiratet und fühle mich bei dem Gedanken wohl, ohne Ehepartner und Familie zu leben. (Verheiratete bitte „Nie“ ankreuzen).'
        },
        {
          number: 25,
          question: 'Durch das Studium in der Bibel und durch christliche Literatur gewinne ich immer wieder neue Einsichten in biblische Wahrheiten.'
        },
        {
          number: 26,
          question: 'Mir macht es Freude, mit Nichtchristen über Jesus und meine persönliche Beziehung zu ihm zu reden.'
        },
        {
          number: 27,
          question: 'Es gelingt mir, fremden Menschen so zu begegnen, dass sie sich in meiner Gegenwart wohl fühlen.'
        },
        {
          number: 28,
          question: 'Ich stelle gerne meinen Besitz und/oder mein Geld für Menschen oder Projekte in der Gemeinde zur Verfügung.'
        },
        {
          number: 29,
          question: 'Gebetsanliegen, die mir andere mitteilen, nehme ich sehr ernst und mache es zu meiner Aufgabe, dafür zu beten.'
        },
        {
          number: 30,
          question: 'Ich neige dazu, „Großes“ von Gott zu erwarten und setze dementsprechende Ziele für die Zukunft.'
        },
        {
          number: 31,
          question: 'Mir macht es Freude, für andere Christen einzuspringen und ihnen Routinearbeiten abzunehmen.'
        },
        {
          number: 32,
          question: 'Es macht mir Freude, eine Gruppe von Christen über einen längeren Zeitraum zu begleiten und sie an meinem Leben teilhaben zu lassen.'
        },
        {
          number: 33,
          question: 'Ich mache die Erfahrung, dass andere Christen gut verstehen und aufnehmen können, was ich an geistlichen Inhalten weitergebe.'
        },
        {
          number: 34,
          question: 'Ich delegiere Aufgaben an andere, die mit mir zusammen arbeiten.'
        },
        {
          number: 35,
          question: 'Mir macht es Spaß, mich in eine andere Kultur hineinzufinden und mich ihrer Lebensweise anzupassen.'
        },
        {
          number: 36,
          question: 'Ich erlebe, dass mein Reden andere Christen anspricht und sie in Bewegung setzt.'
        },
        {
          number: 37,
          question: 'Andere haben mir gesagt, dass sie durch Gespräche mit mir eine entscheidende Hilfe für ihre Lebenssituation bekommen haben.'
        },
        {
          number: 38,
          question: 'Ich kann feststellen, ob falsche Motive hinter einem frommen Auftreten stecken.'
        },
        {
          number: 39,
          question: 'Ich übernehme gerne Verantwortung für Bereiche, die eher sachals personenbezogen sind.'
        },
        {
          number: 40,
          question: 'Andere Christen wenden sich an mich, wenn es darum geht, schwierige oder komplizierte Probleme in der Gemeinde zu lösen.'
        },
        {
          number: 41,
          question: 'Andere Leiter von christlichen Gemeinden bzw. Werken sind bereit, meine Vorschläge anzunehmen und umzusetzen.'
        },
        {
          number: 42,
          question: 'Ich besuche gerne Menschen, die sich in Krankenhäusern, Altenheimen oder ähnlichen Einrichtungen aufhalten.'
        },
        {
          number: 43,
          question: 'Ich bin oft der erste, der sieht, was in der Gemeinde zu tun ist, und dann selbst anpackt.'
        },
        {
          number: 44,
          question: 'Ich bin froh darüber, mehr Kraft und Zeit für Gott und die Gemeinde zu haben, weil ich ledig bin. (Verheiratete bitte „Nie“ ankreuzen).'
        },
        {
          number: 45,
          question: 'Ich kann neugewonnene Erkenntnisse aus der Bibel verständlich formulieren und systematisieren.'
        },
        {
          number: 46,
          question: 'Ich kann anderen Menschen verständlich und überzeugend erklären, wie sie einen Neuanfang mit Jesus machen können.'
        },
        {
          number: 47,
          question: 'Es macht mir keine Mühe, unerwartete Gäste freundlich aufzunehmen und ihnen gegebenenfalls Essen und Unterkunft anzubieten.'
        },
        {
          number: 48,
          question: 'Ich bin innerlich bewegt, wenn ich von finanziellen Nöten im Bereich der Mission oder der Gemeindearbeit höre.'
        },
        {
          number: 49,
          question: 'Längere Zeiten „am Stück“ zu beten, fällt mir nicht schwer.'
        },
        {
          number: 50,
          question: 'Andere halten meine Ziele und Vorstellungen für die Arbeit im Reich Gottes für etwas „unrealistisch“ oder „utopisch“.'
        },
        {
          number: 51,
          question: 'Ich sehe meine Rolle als Christ eher „hinter den Kulissen“, und ich möchte mich dafür einsetzen, dass andere Verantwortliche ihren Dienst effektiver tun können.'
        },
        {
          number: 52,
          question: 'Andere Christen empfinden meine persönliche Begleitung als hilfreich und fördernd für ihr Wachstum im Glauben.'
        },
        {
          number: 53,
          question: 'Mir macht es Freude, vor bzw. in einer Gruppe zu sein und über ein geistliches Thema oder einen Bibeltext zu sprechen.'
        },
        {
          number: 54,
          question: 'Es macht mir Freude, andere für die Mitarbeit zu gewinnen, sie dafür anzuleiten und einzusetzen.'
        },
        {
          number: 55,
          question: 'Ich fühle mich von Menschen aus anderen Kulturkreisen angezogen und umgekehrt auch.'
        },
        {
          number: 56,
          question: 'Ich sehe mich als „Sprachrohr“, durch das Gott aktuelle Anweisungen an meine Gemeinde weitergibt.'
        },
        {
          number: 57,
          question: 'Mir macht es Freude, Christen in ihrer Not eine gewisse Zeit zu begleiten, zu trösten, zu ermutigen bzw. zu ermahnen.'
        },
        {
          number: 58,
          question: 'Ich habe in bestimmten Situationen erkannt, wie „geistlich klingende Worte“ eines anderen doch menschlichen oder dämonischen Ursprungs waren.'
        },
        {
          number: 59,
          question: 'Mir wird von anderen bestätigt, dass ich geschäftliche und organisatorische Aufgaben in der Gemeinde gut abwickeln kann.'
        },
        {
          number: 60,
          question: 'Mir macht es Freude, an Problemen und Schwierigkeiten in der Gemeinde zu arbeiten und dafür Lösungen zu finden.'
        },
        {
          number: 61,
          question: 'Ich habe erlebt, wie eine neue Gemeinde bzw. neue Kreise durch meinen Einsatz entstanden sind.'
        },
        {
          number: 62,
          question: 'Ich nehme immer wieder Gelegenheiten wahr, Menschen mit Behinderungen behilflich zu sein.'
        },
        {
          number: 63,
          question: 'Mich stört es nicht so sehr, wenn ich alleine – oder mit wenigen anderen – Aufgaben in der Gemeinde wahrnehmen muss.'
        },
        {
          number: 64,
          question: 'Mein Ledigsein ermöglicht mir, Aufgaben wahrzunehmen, die ich sonst als Verheirateter von der Zeit und der Kraft her nicht annehmen könnte. (Verheiratete bitte „Nie“ ankreuzen.)'
        },
        {
          number: 65,
          question: 'Mir macht es Freude, viel Zeit beim Lesen der Bibel mit Hilfsmitteln zur Analyse der Schrift zu verbringen.'
        },
        {
          number: 66,
          question: 'Ich spüre den richtigen Zeitpunkt, wann ein Mensch für das Evangelium offen ist.'
        },
        {
          number: 67,
          question: 'Es wird mir nachgesagt, dass ich ein offenes Haus habe, und dass Leute gerne zu mir kommen.'
        },
        {
          number: 68,
          question: 'Ich lege keinen besonderen Wert darauf, dass mein Name genannt bzw. bekannt gemacht wird, wenn ich Menschen oder Projekte im Reich Gottes unterstütze.'
        },
        {
          number: 69,
          question: 'Ich erlebe, wie Gott meine Gebete benutzt, um ganz konkret Menschen und Situationen zu verändern.'
        },
        {
          number: 70,
          question: 'Ich kann andere Christen begeistern, für Dinge zu beten und zu arbeiten, die zunächst ziemlich unmöglich erscheinen.'
        },
        {
          number: 71,
          question: 'Ich merke gleich, wenn andere Christen Hilfe brauchen und biete meinen Einsatz spontan an.'
        },
        {
          number: 72,
          question: 'Ich erlebe, wie junge Christen unter meiner Begleitung „Schritte des geistlichen Wachstums“ gehen und einüben.'
        },
        {
          number: 73,
          question: 'Ich gebe mir viel Mühe, Hilfsmittel zu finden und einzusetzen, mit denen ich Lehrinhalte interessant und ansprechend darstellen kann.'
        },
        {
          number: 74,
          question: 'Andere Christen in der Gemeinde erwarten von mir, dass ich die Initiative ergreife und Dienstbereiche übernehme.'
        },
        {
          number: 75,
          question: 'Ich möchte anderen Völkern in anderen Ländern die Liebe von Jesus Christus persönlich zeigen.'
        },
        {
          number: 76,
          question: 'Christen bestätigen mir, dass in bestimmten Situationen, meine Worte und Botschaften von Gott waren.'
        },
        {
          number: 77,
          question: 'Mir ist es ein Anliegen, mich für einen begrenzten Zeitraum um das persönliche und geistliche Wohlergehen von anderen Christen in seelischen Nöten zu kümmern.'
        },
        {
          number: 78,
          question: 'Ich überprüfe Aussagen von anderen und bewahre dadurch Christen vor Irrtum und falschen Wegen.'
        },
        {
          number: 79,
          question: 'Ich bin gerne „die rechte Hand“ von leitenden Personen in der Gemeinde und unterstütze ihre Ziele mit einer gut durchdachten Organisation.'
        },
        {
          number: 80,
          question: 'Ich empfinde mich nicht als „Theoretiker“, sondern als „Praktiker“ bei der Suche nach Lösungen von Gemeindeproblemen.'
        },
        {
          number: 81,
          question: 'Ich spüre im Gespräch mit Mitarbeitern aus anderen Gemeinden, wie ich mich in ihre Situation hineinversetzen und ihnen konkret helfen kann.'
        },
        {
          number: 82,
          question: 'Ich kann andere Menschen in einer Notsituation oder in ihrer Behinderung durch meine Art aufmuntern und ihnen helfen.'
        },
        {
          number: 83,
          question: 'Andere haben zum Ausdruck gebracht, dass auf mich Verlass ist, wenn es darum geht, praktische Dienste in der Gemeinde zu tun.'
        },
        {
          number: 84,
          question: 'Ich komme mir als ledige Person gegenüber verheirateten Menschen nicht benachteiligt vor. (Verheiratete bitte „Nie“ ankreuzen.)'
        },
        {
          number: 85,
          question: 'Ich habe es erlebt, dass Gott mir neue Ideen, Konzepte und Gedanken geschenkt hat, die für die Gemeinde bedeutungsvoll waren.'
        },
        {
          number: 86,
          question: 'Ich suche Gelegenheiten, um Menschen vom Glauben zu erzählen.'
        },
        {
          number: 87,
          question: 'Es macht mir nicht viel Mühe, unerwartete Gäste herzlich zu empfangen, obwohl meine Wohnung nicht gerade „auf Hochglanz poliert ist“.'
        },
        {
          number: 88,
          question: 'Ich greife spontan „in die Tasche“, wenn ich von irgendwelchen finanziellen Bedürfnissen erfahre und denke nicht lange darüber nach, ob ich es mir leisten kann.'
        },
        {
          number: 89,
          question: 'Ich erfahre, wie Menschen mich um Gebetsunterstützung bitten und dadurch konkrete Hilfe erfahren.'
        },
        {
          number: 90,
          question: 'Ich erlebe es, dass Gott mir großartige Ziele und Perspektiven für Menschen und Situationen schenkt, wo andere nur sehr vorsichtig und zaghaft nachdenken und handeln.'
        },
        {
          number: 91,
          question: 'Andere sagen von mir, dass ich hilfsbereit bin und dass sie bei praktischen Aufgaben mit mir rechnen können.'
        },
        {
          number: 92,
          question: 'Andere Christen sind gerne in meiner Gruppe bzw. in meinem Kreis, wo wir uns mit der Bibel, dem Gebet u.a. beschäftigen.'
        },
        {
          number: 93,
          question: 'Andere bestätigen mir, dass sie durch mich geistliche Wahrheiten nicht nur verstanden haben, sondern sie auch persönlich anwenden können.'
        },
        {
          number: 94,
          question: 'Mir fällt es leicht, für meinen Aufgabenbereich in der Gemeinde einen Plan auszuarbeiten und danach zu handeln.'
        },
        {
          number: 95,
          question: 'Ich lebe und arbeite gerne im Ausland auch für längere Zeit.'
        },
        {
          number: 96,
          question: 'Mein Reden wird in der Gemeinde als klar, verständlich und inspirierend empfunden.'
        },
        {
          number: 97,
          question: 'Ich kann mit Überzeugung und Zielstrebigkeit mit anderen Christen über ihre Nöte und Probleme reden.'
        },
        {
          number: 98,
          question: 'Ich lasse mich nicht so schnell von anderen überzeugen, die durch ihr Auftreten und ihre schönen Worte Christen ungut beeinflussen wollen.'
        },
        {
          number: 99,
          question: 'Ich bin darauf bedacht, meine Zeit täglich effektiv und konsequent zu planen.'
        },
        {
          number: 100,
          question: 'Es fällt mir leicht, Prinzipien an Gottes Wort auf konkrete Problemfälle in der Gemeinde zubeziehen.'
        }
      ]
    )
    function calcGiftAnswers ($event) {
      const val = event.target.value
      const gfkey = parseInt(event.target.name)
      Object.keys(giftAnswersValuation).forEach((key) => {
        if (giftAnswersValuation[key].includes(gfkey)) {
          giftAnswersResults.value[key][gfkey] = val
          // Run throu group an sum all values and set it to summary
          let sum = 0
          giftAnswersValuation[key].forEach(function (value) {
            sum += parseInt(giftAnswersResults.value[key][value])
          })
          giftAnswersResultsSum.value[key] = sum
        }
      })
    }
    return {
      questions,
      calcGiftAnswers,
      giftAnswersResultsSum
    }
  }
}</script>
<style>
.head-option {
  font-size: 10px;
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  background-color: whitesmoke;
  color: #000000;
}
.gray-4 {
  background-color: #0F5132;
  color: whitesmoke;
}
.gray-3 {
  background-color: #42B983;
  color: whitesmoke;
}
.gray-2 {
  background-color: #664D03;
  color: whitesmoke;
}
.gray-1 {
  background-color: #FD7E14;
  color: whitesmoke;
}
.gray-0 {
  background-color: #A52834;
  color: whitesmoke;
}
.cust-row {
  padding: 15px 0px 15px 0px !important;
}

</style>
